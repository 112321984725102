import axios from 'axios';
import config from '@/config/env-constants';

function getProjectId() {
    if (config.currEnv === 'pallet-pooling') {
        return `${config.currEnv}`;
    } else {
        return `ayun-quantity-${config.currEnv}`;
    }
}

export default {
    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    importCompanies(param) {
        let url = `${this.baseUrl}/importCompanies`;
        return axios.post(url, param);
    },

    importStorageLocations(param) {
        let url = `${this.baseUrl}/importStorageLocations`;
        return axios.post(url, param);
    },

    importUsers(param) {
        let url = `${this.baseUrl}/importUsers`;
        return axios.post(url, param);
    },

    importTransportations(param) {
        let url = `${this.baseUrl}/importTransportations`;
        return axios.post(url, param);
    },

    importAssetTypes(param) {
        let url = `${this.baseUrl}/importAssetTypes`;
        return axios.post(url, param);
    },

    importDispatches(param) {
        let url = `${this.baseUrl}/importDispatches`;
        return axios.post(url, param);
    }
}
